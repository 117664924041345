import * as React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import ArrowRight from '../../images/Icons/keyboard_arrow_right.svg'
import './Breadcrumbs.less'
import Truncate from '@konforti/react-truncate'
import { ApplicationState } from 'store'
import { connect } from 'react-redux'

interface Props {
  breadPath: any
  auth: ApplicationState['auth']
}

const Breadcrumb = (props: Props) => (
  <StaticQuery
    query={graphql`
      query {
        breadPageStructure: allContentfulPageStructure {
          edges {
            node1: node {
              navigationName: siteTree1NavigationName
              slug: siteTree1Slug
            }
            node2: node {
              navigationName: siteTree2NavigationName
              slug: siteTree2Slug
            }
            node3: node {
              navigationName: siteTree3NavigationName
              slug: siteTree3Slug
            }
            node4: node {
              navigationName: siteTree4NavigationName
              slug: siteTree4Slug
            }
          }
        }
        breadArticles: allContentfulArticle {
          edges {
            node {
              navigationName
              slug
            }
          }
        }
        breadOpinionArticles: allContentfulOpinionArticle {
          edges {
            node {
              navigationName
              slug
            }
          }
        }
        breadMemberArticles: allContentfulMemberArticle {
          edges {
            node {
              navigationName
              slug
            }
          }
        }
        breadArticleListPages: allContentfulArticleListPage {
          edges {
            node {
              navigationName
              slug
            }
          }
        }
      }
    `}
    render={(data) => {
      const { breadPath } = props
      var breadcrumbs: [] = breadPath.split('/').filter(Boolean)
      let breadcrumbUrl: Array<string> = []

      const breadArticles = data.breadArticles.edges
      const breadMemberArticles = data.breadMemberArticles.edges
      const breadOpinionArticles = data.breadOpinionArticles.edges
      const breadArticleListPages = data.breadArticleListPages.edges
      const breadPageStructure = data.breadPageStructure.edges
      let breadTopLevel: Array<any> = []
      breadPageStructure.forEach(function (e) {
        const one = e.node1
        const two = e.node2
        const three = e.node3
        const four = e.node4
        breadTopLevel.push({ node: one }, { node: two }, { node: three }, { node: four })
      })

      let breadNames = breadArticles.concat(
        breadOpinionArticles,
        breadMemberArticles,
        breadArticleListPages,
        breadTopLevel,
      )

      return (
        <nav className="Page-container u-paddingVxsm" aria-label="Breadcrumb">
          <ol className="breadcrumbs">
            <li className="breadcrumbs-item">
              {props.auth.status !== 'LOGGED_IN' || breadcrumbs.length > 1 ? (
                <Link className="Link" to="/">
                  Start
                </Link>
              ) : (
                <Link className="Link" to="/mina-sidor">
                  Mitt DIK
                </Link>
              )}
            </li>
            {breadcrumbs.map((value, index: number) => {
              breadcrumbUrl.push(value)
              let navName = breadNames.find((find) => find != null && find.node.slug == value)
              return (
                <li className="breadcrumbs-item" key={index}>
                  <ArrowRight className="breadcrumbs-icon" aria-hidden="true" />
                  <Link className="Link" to={`/${breadcrumbUrl.join('/')}`}>
                    {navName && <Truncate truncFrom="end">{navName.node.navigationName}</Truncate>}
                  </Link>
                </li>
              )
            })}
          </ol>
        </nav>
      )
    }}
  />
)

export default connect((state: ApplicationState) => ({ auth: state.auth }))(Breadcrumb)
