import React from 'react'
import { graphql, Link } from 'gatsby'

import SEO from '../../components/seo'
import Breadcrumb from 'components/Breadcrumbs/Breadcrumbs'
import LayoutNew from 'layout/LayoutNew'

const ArticleIndex = ({ pageContext, location, data }) => {
  const breadPageStructure = data.breadPageStructure.edges

  let breadTopLevel: Array<any> = []
  breadPageStructure.forEach(function (e) {
    const one = e.node1
    const two = e.node2
    const three = e.node3
    const four = e.node4
    breadTopLevel.push({ node: one }, { node: two }, { node: three }, { node: four })
  })
  return (
    <LayoutNew>
      <SEO description={pageContext.description} title={pageContext.navigationname} />
      <Breadcrumb breadPath={location.pathname} />
      <section className="Page-container u-marginVxlg">
        <h1 className="Heading Heading--h1">{pageContext.navigationname}</h1>
        <ul className="List">
          {pageContext.articles &&
            pageContext.articles.map((value, index: number) => {
              return (
                <li className="List-item" key={index}>
                  <Link className="Link" to={`${location.pathname}/${value.slug}`}>
                    {value.navigationName}
                  </Link>
                </li>
              )
            })}
        </ul>
      </section>
    </LayoutNew>
  )
}

export default ArticleIndex

export const queryArticleIndex = graphql`
  query {
    breadPageStructure: allContentfulPageStructure {
      edges {
        node1: node {
          slug: siteTree1Slug
          navigationName: siteTree1NavigationName
        }
        node2: node {
          slug: siteTree2Slug
          navigationName: siteTree2NavigationName
        }
        node3: node {
          slug: siteTree3Slug
          navigationName: siteTree3NavigationName
        }
        node4: node {
          slug: siteTree4Slug
          navigationName: siteTree4NavigationName
        }
      }
    }
  }
`
